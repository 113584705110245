import { TooltipItem } from "chart.js";
import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  useLazyCheckSpaceClusterAvailabilityQuery,
  useLazyGetConsumedSpaceClusterByYearAndMonthQuery,
} from "../../../redux/api/dashboard/dashboardAPI";
import {
  useLazyEnergyPredictionByYearMonthQuery,
  useLazyGetEnergyConsumptionQuery,
  useLazyViewSpaceClusterEnergyByYearMonthQuery,
} from "../../../redux/api/energy-consumption/energyConsumptionAPI";
import { selectBillingSpaceFilter } from "../../../redux/features/billing-space-filter/billing-space-filter-slice";
import { selectProfile } from "../../../redux/features/user/user-slice";
import TimePeriodSwitcher from "../../../shared/components/time-period-switcher/time-period-switcher";
import ICheckSpaceClusterAvailabilityResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/check-space-cluster-availability-response-dto";
import { EConsumptionViewTypes } from "../../../shared/oversight-core/enums/consumption-view-types";
import { EEnergyViewType } from "../../../shared/oversight-core/enums/energy-viewType";
import { EProviders } from "../../../shared/oversight-core/enums/providers";
import { EScheduleViewType } from "../../../shared/oversight-core/enums/schedule-view-type";
import { ETimePeriod } from "../../../shared/oversight-core/enums/time-period";
import { EUsageDataFrom } from "../../../shared/oversight-core/enums/usage-data-from";
import { IEnergyView } from "../../../shared/oversight-core/interfaces/entities/energy-view";
import AppDatePicker from "../../../shared/oversight-core/ui-elements/app-date-picker/app-date-picker";
import AppTabs from "../../../shared/oversight-core/ui-elements/app-tabs/app-tabs";
import AreaChart from "../../../shared/oversight-core/ui-elements/area-chart/area-chart";
import AppButton from "../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import SpinnerModal from "../../../shared/oversight-core/ui-elements/spinner/spinner";
import StatsView from "../../../shared/oversight-core/ui-elements/stats-view/stats-view";
import { offsetToString } from "../../../shared/oversight-core/utils/offsetToString";
import translate from "../../../shared/oversight-core/utils/translate";
import AddUpdateBillDetailsModal from "./components/add-update-bill-details-modal/add-update-bill-details-modal";
import styles from "./energy-usage.module.scss";

const timePeriods = [
  { label: "Monthly", value: ETimePeriod.MONTHLY },
  { label: "Daily", value: ETimePeriod.DAILY },
];

const defaultSpaceClusterAvailability: ICheckSpaceClusterAvailabilityResponseDTO =
  {
    controllersAvailable: false,
    manualSchedulesAvailable: false,
    semiAutomatedSchedulesAvailable: false,
    consumedSpaceClusterAvailable: false,
  };

const EnergyUsage = () => {
  const billingSpaceFilter = useSelector(selectBillingSpaceFilter);
  const profile = useSelector(selectProfile);

  const [hasActualConsumption, setHasActualConsumption] = useState(false);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState(0);
  const [selectedButton, setSelectedButton] = useState(1);
  const [showAddUpdateBillDetailsModal, setShowAddUpdateBillDetailsModal] =
    useState(false);
  const [labels, setLabels] = useState<string[]>([]);
  const [actualConsumption, setActualConsumption] = useState<number[]>([]);
  const [actualConsumptionFrom, setActualConsumptionFrom] = useState<
    EUsageDataFrom[]
  >([]);
  const [estimatedConsumption, setEstimatedConsumption] = useState<number[]>(
    []
  );
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [actualUsage, setActualUsage] = useState<IEnergyView>({
    energyBill: 0,
    energyInUnits: 0,
  });
  const [usagePrediction, setUsagePrediction] = useState<
    IEnergyView | undefined
  >(undefined);
  const [isEnergyUpdated, setIsEnergyUpdated] = useState(false);
  const [checkSpaceClusterAvailability, setCheckSpaceClusterAvailability] =
    useState<ICheckSpaceClusterAvailabilityResponseDTO>({
      ...defaultSpaceClusterAvailability,
    });

  const [getEnergyConsumption, { isFetching: isFetchingGetEnergyConsumption }] =
    useLazyGetEnergyConsumptionQuery();
  const [
    getConsumedSpaceClusterByYearAndMonth,
    { isFetching: isFetchingGetConsumedSpaceClusterByYearAndMonth },
  ] = useLazyGetConsumedSpaceClusterByYearAndMonthQuery();
  const [
    viewSpaceClusterEnergyByYearMonth,
    { isFetching: isFetchingSpaceClusterByYearMonth },
  ] = useLazyViewSpaceClusterEnergyByYearMonthQuery();
  const [triggerCheckSpaceClusterAvailability] =
    useLazyCheckSpaceClusterAvailabilityQuery();
  const [
    triggerEnergyPredictionByYearMonth,
    { isFetching: isFetchingEnergyPredictionByYearMonth },
  ] = useLazyEnergyPredictionByYearMonthQuery();

  useEffect(() => {
    if (billingSpaceFilter?.spaceCluster?.id) {
      triggerCheckSpaceClusterAvailability({
        spaceClusterId: billingSpaceFilter.spaceCluster.id,
        zoneOffset: offsetToString(moment().utcOffset()),
      })
        .unwrap()
        .then((res: ICheckSpaceClusterAvailabilityResponseDTO) => {
          setCheckSpaceClusterAvailability(res);
        })
        .catch(() => {
          setCheckSpaceClusterAvailability({
            ...defaultSpaceClusterAvailability,
          });
        });
    }
  }, [billingSpaceFilter?.spaceCluster?.id]);

  useEffect(() => {
    const viewEnergyAs: EEnergyViewType =
      selectedButton === 1 ? EEnergyViewType.UNIT : EEnergyViewType.BILL;
    const viewConsumptionAs: EConsumptionViewTypes = EConsumptionViewTypes.ALL;
    const viewScheduleAs: EScheduleViewType = EScheduleViewType.ALL;
    const zoneOffset = offsetToString(moment().utcOffset());

    if (billingSpaceFilter?.spaceCluster?.id) {
      if (selectedTimePeriod === 0) {
        getEnergyConsumption({
          spaceClusterId: billingSpaceFilter.spaceCluster.id,
          viewEnergyAs,
          viewConsumptionAs,
          viewScheduleAs,
          year: moment(selectedDate).year(),
          zoneOffset,
        })
          .unwrap()
          .then((res) => {
            setLabels(
              res.orderedMonthlyEnergySummary.map((omes) => {
                const month = omes.monthName.toLowerCase();
                return month.charAt(0).toUpperCase() + month.slice(1);
              })
            );
            setActualConsumption(
              res.orderedMonthlyEnergySummary.map((omes) =>
                selectedButton === 1
                  ? omes.energySummaryView.consumedEnergySummary.energyInUnits
                  : omes.energySummaryView.consumedEnergySummary.energyBill
              )
            );
            setActualConsumptionFrom(
              res.orderedMonthlyEnergySummary.map(
                (omes) => omes.energySummaryView.consumedUsageDataFrom
              )
            );
            setEstimatedConsumption(
              res.orderedMonthlyEnergySummary.map((omes) =>
                selectedButton === 1
                  ? (checkSpaceClusterAvailability.controllersAvailable
                      ? omes.energySummaryView
                          .semiAutomatedScheduleEnergySummary
                      : omes.energySummaryView.manualScheduleEnergySummary
                    ).energyInUnits
                  : (checkSpaceClusterAvailability.controllersAvailable
                      ? omes.energySummaryView
                          .semiAutomatedScheduleEnergySummary
                      : omes.energySummaryView.manualScheduleEnergySummary
                    ).energyBill
              )
            );
          })
          .catch(() => {
            setLabels([]);
            setActualConsumption([]);
            setActualConsumptionFrom([]);
            setEstimatedConsumption([]);
          });
      } else if (selectedTimePeriod === 1) {
        viewSpaceClusterEnergyByYearMonth({
          spaceClusterId: billingSpaceFilter.spaceCluster.id,
          viewEnergyAs,
          viewConsumptionAs,
          viewScheduleAs,
          year: moment(selectedDate).year(),
          month: moment(selectedDate).month() + 1,
          zoneOffset,
        })
          .unwrap()
          .then((res) => {
            setLabels(
              res.orderedDailyEnergySummary.map((odes) => String(odes.day))
            );
            setActualConsumption(
              res.orderedDailyEnergySummary.map((odes) =>
                selectedButton === 1
                  ? odes.energySummaryView.consumedEnergySummary.energyInUnits
                  : odes.energySummaryView.consumedEnergySummary.energyBill
              )
            );
            setActualConsumptionFrom(
              res.orderedDailyEnergySummary.map(
                (odes) => odes.energySummaryView.consumedUsageDataFrom
              )
            );
            setEstimatedConsumption(
              res.orderedDailyEnergySummary.map((odes) =>
                selectedButton === 1
                  ? (checkSpaceClusterAvailability.controllersAvailable
                      ? odes.energySummaryView
                          .semiAutomatedScheduleEnergySummary
                      : odes.energySummaryView.manualScheduleEnergySummary
                    ).energyInUnits
                  : (checkSpaceClusterAvailability.controllersAvailable
                      ? odes.energySummaryView
                          .semiAutomatedScheduleEnergySummary
                      : odes.energySummaryView.manualScheduleEnergySummary
                    ).energyBill
              )
            );
          })
          .catch(() => {
            setLabels([]);
            setActualConsumption([]);
            setActualConsumptionFrom([]);
            setEstimatedConsumption([]);
          });
      }
    }
  }, [
    billingSpaceFilter.spaceCluster.id,
    selectedButton,
    selectedDate,
    isEnergyUpdated,
    selectedTimePeriod,
    checkSpaceClusterAvailability,
  ]);

  useEffect(() => {
    if (billingSpaceFilter?.spaceCluster?.id) {
      getConsumedSpaceClusterByYearAndMonth({
        spaceClusterId: billingSpaceFilter.spaceCluster.id,
        year: moment(selectedDate).year(),
        month: moment(selectedDate).month() + 1,
      })
        .unwrap()
        .then((res) => {
          setActualUsage(res.energy);
          setHasActualConsumption(res.energy.energyInUnits > 0);
        })
        .catch(() => {
          setActualUsage({ energyBill: 0, energyInUnits: 0 });
          setHasActualConsumption(false);
        });

      triggerEnergyPredictionByYearMonth({
        spaceClusterId: billingSpaceFilter.spaceCluster.id,
        year: moment(selectedDate).year(),
        month: moment(selectedDate).month() + 1,
        viewEnergyAs: EEnergyViewType.ALL,
      })
        .unwrap()
        .then((res) => {
          setUsagePrediction(res.predictedEnergy);
        })
        .catch(() => {
          setUsagePrediction(undefined);
        });
    }
  }, [billingSpaceFilter?.spaceCluster?.id, selectedDate, isEnergyUpdated]);

  return (
    <>
      <div className="container-white">
        {isFetchingGetConsumedSpaceClusterByYearAndMonth ||
        isFetchingEnergyPredictionByYearMonth ? (
          <SpinnerModal
            show={
              isFetchingGetConsumedSpaceClusterByYearAndMonth ||
              isFetchingEnergyPredictionByYearMonth
            }
            withOverlay={false}
          />
        ) : (
          <Row className="align-items-center py-2 justify-content-between">
            <Col lg={8} xl={9}>
              <Row className="align-items-center">
                <Col lg={6} xl={4}>
                  <StatsView
                    labelValue={
                      usagePrediction
                        ? `${usagePrediction.energyInUnits?.toFixed(
                            2
                          ) ?? 0} Units | Rs. ${usagePrediction.energyBill?.toFixed(
                            2
                          ) ?? 0}`
                        : "Error"
                    }
                    title="Usage Prediction"
                    variant="bg-icon-5"
                    fontStyle="small"
                    columnClass="col-3"
                    fontSize={10}
                  />
                </Col>
                {hasActualConsumption && (
                  <Col
                    xs={9}
                    sm={10}
                    md={10}
                    lg={4}
                    xl={3}
                    className="mt-4 mt-lg-0"
                  >
                    <StatsView
                      labelValue={`${actualUsage.energyInUnits?.toFixed(
                        2
                      ) ?? 0} Units | Rs. ${actualUsage.energyBill?.toFixed(2) ?? 0}`}
                      title="Actual Usage"
                      variant="bg-icon-6"
                      fontStyle="small"
                      columnClass="col-3"
                      fontSize={10}
                    />
                  </Col>
                )}
                {profile &&
                  profile.serviceProvider !== EProviders.LECO &&
                  (hasActualConsumption ? (
                    <Col xs="auto" sm="auto" className="mt-4 mt-lg-0">
                      <AppButton
                        text="Edit"
                        iconName="drive_file_rename_outline"
                        iconOnly
                        size="medium"
                        onClick={() => {
                          setShowAddUpdateBillDetailsModal(true);
                        }}
                      />
                    </Col>
                  ) : (
                    <Col lg={5} xl={4} className="mt-4 mt-lg-0">
                      <AppButton
                        text="Input Usage"
                        size="medium"
                        onClick={() => {
                          setShowAddUpdateBillDetailsModal(true);
                        }}
                      />
                    </Col>
                  ))}
              </Row>
            </Col>
            <Col lg={4} xl={3} className="mt-4 mt-lg-0">
              <AppDatePicker
                monthYearPicker
                selectedDate={selectedDate}
                onChange={(date) => {
                  setSelectedDate(date);
                }}
                maxDate={moment().endOf("month").toDate()}
              />
            </Col>
          </Row>
        )}
      </div>
      <div className="container-white mt-4">
        <Row className="align-items-center justify-content-end">
          <Col className="col mt-4 mt-lg-0" sm={5} md={5} lg={4} xl={3} xxl={2}>
            <TimePeriodSwitcher
              timePeriods={timePeriods}
              selectedTimePeriod={selectedTimePeriod}
              setSelectedTimePeriod={setSelectedTimePeriod}
            />
          </Col>
          <Col className="col-auto mt-4 mt-lg-0">
            <AppTabs
              buttons={[
                {
                  buttonName: "Units",
                  selectedColor: "purpleColor",
                  id: 1,
                },
                {
                  buttonName: "Bill",
                  selectedColor: "purpleColor",
                  id: 2,
                },
              ]}
              onChange={(id: number) => {
                setSelectedButton(id);
              }}
              selectedTabId={selectedButton}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            {!isFetchingGetEnergyConsumption &&
            !isFetchingSpaceClusterByYearMonth ? (
              <AreaChart
                borderColor1={"#EEE741"}
                borderColor2={"#D84560"}
                backgroundColor1={"#F5FFCD4D"}
                backgroundColor2={"#CC292917"}
                label1={`Estimated Usage (${translate(
                  `scheduleViewType.${
                    checkSpaceClusterAvailability.controllersAvailable
                      ? EScheduleViewType.SEMI_AUTOMATED
                      : EScheduleViewType.MANUAL
                  }`
                )})`}
                label2={"Actual Usage"}
                yAxesUnit={selectedButton === 1 ? "Units" : "Rs."}
                labels={labels}
                data1={estimatedConsumption}
                data2={actualConsumption}
                hasSecondDataSet={true}
                isChartDataAvailable={
                  (estimatedConsumption.length > 0 &&
                    estimatedConsumption.some((ec) => ec > 0)) ||
                  (actualConsumption.length > 0 &&
                    actualConsumption.some((ac) => ac > 0))
                }
                labelCallback={(context: TooltipItem<"line">) => {
                  let label: string[] = [];

                  if (context.parsed.y) {
                    if (selectedButton !== 1) {
                      label = [
                        `Rs. ${context.parsed.y?.toFixed(2)}`,
                        context.datasetIndex === 1
                          ? `(${translate(
                              `usageDataFrom.${
                                actualConsumptionFrom[context.dataIndex]
                              }`
                            )})`
                          : "",
                      ];
                    } else {
                      label = [
                        `${context.parsed.y?.toFixed(2)} Units`,
                        context.datasetIndex === 1
                          ? `(${translate(
                              `usageDataFrom.${
                                actualConsumptionFrom[context.dataIndex]
                              }`
                            )})`
                          : "",
                      ];
                    }
                  }
                  return label;
                }}
              />
            ) : (
              <div className={styles.modalContainer}>
                <SpinnerModal
                  show={
                    isFetchingGetEnergyConsumption ||
                    isFetchingSpaceClusterByYearMonth
                  }
                  withOverlay={false}
                />
              </div>
            )}
          </Col>
        </Row>
      </div>
      <AddUpdateBillDetailsModal
        closeAddUpdateBillDetailsDataModal={() => {
          setShowAddUpdateBillDetailsModal(false);
        }}
        onClose={() => setShowAddUpdateBillDetailsModal(false)}
        onCancel={() => setShowAddUpdateBillDetailsModal(false)}
        show={showAddUpdateBillDetailsModal}
        month={moment(selectedDate).month() + 1}
        year={moment(selectedDate).year()}
        rootSpaceId={billingSpaceFilter.spaceCluster.id}
        rootSpaceName={""}
        actualConsumption={{
          consumedEnergySummary: actualUsage,
          manualScheduleEnergySummary: {
            energyInUnits: 0,
            energyBill: 0,
          },
          semiAutomatedScheduleEnergySummary: {
            energyInUnits: 0,
            energyBill: 0,
          },
          consumedUsageDataFrom: EUsageDataFrom.TELEMETRY,
        }}
        updateActualConsumption={() => {
          setIsEnergyUpdated((ps) => !ps);
        }}
        isEditMode={true}
        accountNumber={""}
        isLoading={isFetchingGetEnergyConsumption}
      />
    </>
  );
};

export default EnergyUsage;
